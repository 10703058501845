@media only screen and (min-device-width: 20em) {
  .progress-bar {
    font-size: 20px;
  }

  .skillsCol {
    width: 350px;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 4px;
    margin-right: 1px;
  }

  .firstRow {
    margin-top: 10px;
  }

  .edCol {
    position: unset;
    height: auto;
    margin-top: 5px;
  }

  .expRow {
    margin-top: 5px;
  }

  .jobRow {
    margin-top: -3px;
    margin-bottom: 7px;
  }

  .pdl {
    display: block;
    width: 86px;
  }

  .pdlm {
    display: flex;
    position: absolute;
    margin-left: 13px;
  }

  .pdlmr {
    display: block;
    position: relative;
  }

  .desListCol {
    padding-left: 1px;
    padding-right: 5px;
    margin-bottom: 15px;
  }

  ul {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 0rem;
  }
}

@media only screen and (min-device-width: 768px) {
  .progress-bar {
    font-size: 16px;
  }
  .skillsCol {
    width: 236px;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 4px;
    margin-right: 1px;
  }

  .firstRow {
    margin-top: 10px;
  }

  .edCol {
    margin-left: 232px;
    height: auto;
    position: absolute;
    width: 361px;
  }

  .expRow {
    margin-left: 217px;
    padding-left: 6px;
    width: 361px;
    margin-top: 25px;
  }

  .jobRow {
    margin-left: 217px;
    width: 500px;
    margin-top: -3px;
    margin-bottom: 2px;
  }

  .pdl {
    display: block;
    width: 86px;
  }

  .pdlm {
    display: flex;
    position: absolute;
    margin-left: 4px;
  }

  .pdlmr {
    display: block;
    position: relative;
  }

  .desListCol {
    padding-left: 1px;
    padding-right: 5px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  ul {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 0rem;
  }
}

@media only screen and (min-device-width: 1440px) {
  .progress-bar {
    font-size: 20px;
  }
  .skillsCol {
    width: 350px;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 4px;
    margin-right: 1px;
  }

  .firstRow {
    margin-top: 10px;
  }

  .edCol {
    padding-left: 1%;
    padding-right: 1px;
    margin-left: 349px;
    height: auto;
    position: absolute;
    width: 361px;
  }

  .expRow {
    margin-left: 334px;
    padding-left: 19px;
    width: 776px;
    margin-top: 25px;
  }

  .jobRow {
    margin-left: 334px;
    width: 776px;
    margin-top: -3px;
  }

  .pdl {
    margin-left: 14px;
  }

  .pdlm {
    margin: auto;
    margin-left: 80px;
    margin-right: 1em;
  }

  .pdlmr {
    text-align: end;
  }

  .desListCol {
    padding-left: 1px;
    padding-right: 1px;
    margin-right: 1px;
    margin-top: 7px;
    margin-bottom: 16px;
  }

  ul {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 0rem;
  }
}
