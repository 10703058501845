/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */

  .col {
    padding-left: 1%;
    padding-right: 1px;
    margin-right: 1px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto ;
    grid-column-gap: 5;
  }

  img.picOfMe {
    display: block;
    margin-left: 10px;
    margin-right: 15px;
    margin-bottom: 10px;
    margin-top: 30px;
    height: auto;
  }

  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .aboutMeSection {
    text-align: justify;
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 5px;
    margin-right: 10px;
    font-family: "Martel", serif;
  }
  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .aboutMeText {
    font-size: 35px;
    font-family: "Bungee", cursive;
  }

@media only screen and (max-width: 768px) {
  .col {
    padding-left: 1%;
    padding-right: 1px;
    margin-right: 1px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 2;
  }

  img.picOfMe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 30px;
    height: auto;
  }

  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .aboutMeSection {
    text-align: justify;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 9px;
    margin-right: 19px;
    margin-left: 19px;
    font-family: "Martel", serif;
  }
  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .aboutMeText {
    font-size: 35px;
    font-family: "Bungee", cursive;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  img.picOfMe {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 29px;
    height: auto;
  }
  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 5;
  }
  .aboutMeSection {
    text-align: justify;
    margin-left: 1.5%;
    margin-right: 1.5%;
    padding-right: 1%;
    padding-left: 1%;
    font-family: "Martel", serif;
  }
  .aboutMeText {
    font-size: 25px;
    font-family: "Martel", serif;
  }
}
