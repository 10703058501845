.headerPic {
  width: 100%;
  height: 490px;
}

.headerContainer {
  position: relative;
  text-align: center;
}

.centeredText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-style: normal;
  color: aliceblue;
  font-size: 2vw;
  font-family: "Martel", serif;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .centeredText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    color: aliceblue;
    font-size: 3vw;
    font-family: "Martel", serif;
    text-align: center;
  }
  .headerPic {
    width: 100%;
    height: 330px;
  }
}



@media only screen and (max-width: 600px) {
  .centeredText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    color: aliceblue;
    font-size: 4vw;
    font-family: "Martel", serif;
    text-align: center;
  }
  .headerPic {
    width: 100%;
    height: 200px;
  }
}

