.headerPic {
  width: 100%;
  height: 490px;
}

.headerContainer {
  position: relative;
  text-align: center;
}

.centeredText {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-style: normal;
  color: aliceblue;
  font-size: 2vw;
  font-family: "Martel", serif;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .centeredText {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-style: normal;
    color: aliceblue;
    font-size: 3vw;
    font-family: "Martel", serif;
    text-align: center;
  }
  .headerPic {
    width: 100%;
    height: 330px;
  }
}



@media only screen and (max-width: 600px) {
  .centeredText {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    font-style: normal;
    color: aliceblue;
    font-size: 4vw;
    font-family: "Martel", serif;
    text-align: center;
  }
  .headerPic {
    width: 100%;
    height: 200px;
  }
}


@media only screen and (min-device-width: 20em) {
  .progress-bar {
    font-size: 20px;
  }

  .skillsCol {
    width: 350px;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 4px;
    margin-right: 1px;
  }

  .firstRow {
    margin-top: 10px;
  }

  .edCol {
    position: unset;
    height: auto;
    margin-top: 5px;
  }

  .expRow {
    margin-top: 5px;
  }

  .jobRow {
    margin-top: -3px;
    margin-bottom: 7px;
  }

  .pdl {
    display: block;
    width: 86px;
  }

  .pdlm {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    margin-left: 13px;
  }

  .pdlmr {
    display: block;
    position: relative;
  }

  .desListCol {
    padding-left: 1px;
    padding-right: 5px;
    margin-bottom: 15px;
  }

  ul {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 0rem;
  }
}

@media only screen and (min-device-width: 768px) {
  .progress-bar {
    font-size: 16px;
  }
  .skillsCol {
    width: 236px;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 4px;
    margin-right: 1px;
  }

  .firstRow {
    margin-top: 10px;
  }

  .edCol {
    margin-left: 232px;
    height: auto;
    position: absolute;
    width: 361px;
  }

  .expRow {
    margin-left: 217px;
    padding-left: 6px;
    width: 361px;
    margin-top: 25px;
  }

  .jobRow {
    margin-left: 217px;
    width: 500px;
    margin-top: -3px;
    margin-bottom: 2px;
  }

  .pdl {
    display: block;
    width: 86px;
  }

  .pdlm {
    display: -webkit-flex;
    display: flex;
    position: absolute;
    margin-left: 4px;
  }

  .pdlmr {
    display: block;
    position: relative;
  }

  .desListCol {
    padding-left: 1px;
    padding-right: 5px;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  ul {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 0rem;
  }
}

@media only screen and (min-device-width: 1440px) {
  .progress-bar {
    font-size: 20px;
  }
  .skillsCol {
    width: 350px;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 4px;
    margin-right: 1px;
  }

  .firstRow {
    margin-top: 10px;
  }

  .edCol {
    padding-left: 1%;
    padding-right: 1px;
    margin-left: 349px;
    height: auto;
    position: absolute;
    width: 361px;
  }

  .expRow {
    margin-left: 334px;
    padding-left: 19px;
    width: 776px;
    margin-top: 25px;
  }

  .jobRow {
    margin-left: 334px;
    width: 776px;
    margin-top: -3px;
  }

  .pdl {
    margin-left: 14px;
  }

  .pdlm {
    margin: auto;
    margin-left: 80px;
    margin-right: 1em;
  }

  .pdlmr {
    text-align: end;
  }

  .desListCol {
    padding-left: 1px;
    padding-right: 1px;
    margin-right: 1px;
    margin-top: 7px;
    margin-bottom: 16px;
  }

  ul {
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 0rem;
  }
}

.imgThumbnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  width: 150px; /* Set a small width */
}
.imgThumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

p {
  position: absolute;
  text-align: center;
}

.rel-pos {
  position: relative;
}

/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */

  .col {
    padding-left: 1%;
    padding-right: 1px;
    margin-right: 1px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto ;
    grid-column-gap: 5;
  }

  img.picOfMe {
    display: block;
    margin-left: 10px;
    margin-right: 15px;
    margin-bottom: 10px;
    margin-top: 30px;
    height: auto;
  }

  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .aboutMeSection {
    text-align: justify;
    font-size: 20px;
    margin-top: 24px;
    margin-bottom: 5px;
    margin-right: 10px;
    font-family: "Martel", serif;
  }
  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .aboutMeText {
    font-size: 35px;
    font-family: "Bungee", cursive;
  }

@media only screen and (max-width: 768px) {
  .col {
    padding-left: 1%;
    padding-right: 1px;
    margin-right: 1px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 2;
  }

  img.picOfMe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-top: 30px;
    height: auto;
  }

  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  .aboutMeSection {
    text-align: justify;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 9px;
    margin-right: 19px;
    margin-left: 19px;
    font-family: "Martel", serif;
  }
  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 1%;
    padding-bottom: 1%;
  }
  .aboutMeText {
    font-size: 35px;
    font-family: "Bungee", cursive;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  img.picOfMe {
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 29px;
    height: auto;
  }
  .aboutMeTitle {
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 25px;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 5;
  }
  .aboutMeSection {
    text-align: justify;
    margin-left: 1.5%;
    margin-right: 1.5%;
    padding-right: 1%;
    padding-left: 1%;
    font-family: "Martel", serif;
  }
  .aboutMeText {
    font-size: 25px;
    font-family: "Martel", serif;
  }
}

