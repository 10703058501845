.imgThumbnail {
  border: 1px solid #ddd; /* Gray border */
  border-radius: 4px; /* Rounded border */
  padding: 5px; /* Some padding */
  width: 150px; /* Set a small width */
}
.imgThumbnail:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

p {
  position: absolute;
  text-align: center;
}

.rel-pos {
  position: relative;
}
